<template>
  <BaseLayout :noHeader="true" :classes="{'ion-padding': true, 'bg-white': true}">

    <swiper :modules="modules" :autoplay="false" :keyboard="true" :pagination="true">
      <swiper-slide>
        <div class="logo">
          <ion-img src="/assets/logo.jpg" />
        </div>

        <h1>
          Willkommen 1
        </h1>

        <p>
          Unser Team aus erfahrenen Chirurgen beantwort Dir Deine individuelle Beauty-Anfrage. Unkompliziert und schnell.
        </p>
      </swiper-slide>

      <swiper-slide>
        <div class="logo">
          <ion-img src="/assets/logo.jpg" />
        </div>

        <h1>
          Willkommen 2
        </h1>

        <p>
          Unser Team aus erfahrenen Chirurgen beantwort Dir Deine individuelle Beauty-Anfrage. Unkompliziert und schnell.
        </p>
      </swiper-slide>

      <swiper-slide>
        <div class="logo">
          <ion-img src="/assets/logo.jpg" />
        </div>

        <h1>
          Willkommen 3
        </h1>

        <p>
          Unser Team aus erfahrenen Chirurgen beantwort Dir Deine individuelle Beauty-Anfrage. Unkompliziert und schnell.
        </p>
      </swiper-slide>
    </swiper>

    <ion-button @click="() => ionRouter.push('/request/home')" expand="block" shape="round">Anfrage Starten</ion-button>
    <!-- <ion-button @click="() => ionRouter.push('/information')" expand="block" shape="round" fill="clear">Informationen zur Praxis</ion-button> -->

  </BaseLayout>
</template>

<script>
import { IonImg, IonButton } from '@ionic/vue';
import { useIonRouter } from '@ionic/vue';
import { Autoplay, Keyboard, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import '@ionic/vue/css/ionic-swiper.css';

export default {
  name: 'HomePage',
  setup() {
    const ionRouter = useIonRouter();
    return {
      ionRouter,
      modules: [Autoplay, Keyboard, Pagination],
    };
  },
  components: {
    IonImg, IonButton, Swiper, SwiperSlide
  },
  methods: {
  }

};
</script>

<style scoped>
  .logo {
    padding: 150px 40px 40px 40px;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin: 20px 0 50px 0;
    padding: 0px 30px;
  }

  ion-button {
    margin: 15px 30px 0 30px;
  }

  .swiper {
      /* --bullet-background-active: ; */
      --bullet-background: #888 !important;

  }
  .swiper-slide {
    flex-direction: column;
  }
</style>
