<template>
  <BaseLayout :noHeader="true" :classes="{'ion-padding': true}">

    <div class="logo">
      <ion-img src="/assets/hugging.png" />
    </div>

    <h1>
      Herzlich Willkommenasd
    </h1>
    <p>
      Seit nun über 15 Jahren beweist die Schönheitschirurgie
      KÖ-Aesthetics in Düsseldorf ihre Expertise in Hinblick auf
      Eingriffe im Bereich der Ästhetisch-Plastischen Chirurgie.
    </p>

  <ion-grid>

    <ion-row>
      <ion-col size="6">
        
      </ion-col>
      <ion-col style="background:#f00">
        2
      </ion-col>
      <ion-col size="5" style="background:#0f0">
        3
      </ion-col>
    </ion-row>

  </ion-grid>

    <router-link to="/request">
      <ion-button expand="block">Informationen zur Praxis</ion-button>
    </router-link>

  </BaseLayout>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonImg, IonButton } from '@ionic/vue';

export default {
  name: 'HomePage',
  setup() {
    return {
    };
  },
  components: {
    IonGrid, IonRow, IonCol, IonImg, IonButton
  },
  methods: {
  }

};
</script>

<style scoped>
  .logo {
    padding: 100px 40px;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
  }
</style>