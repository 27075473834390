<template>
  <ion-app>

    <ion-menu side="start" menu-id="first" content-id="main">
      <ion-header>
        <ion-toolbar ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-toggle>
              <ion-menu-button></ion-menu-button>
            </ion-menu-toggle>
          </ion-buttons>

          <ion-breadcrumbs mode="ios" slot="start">
            <ion-breadcrumb>KÖ Aesthetics</ion-breadcrumb>
            <ion-breadcrumb>Menu</ion-breadcrumb>
          </ion-breadcrumbs>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item :router-link="{ path: '/request/home'}" @click="closeMenu()">
            <ion-icon :icon="clipboardOutline"></ion-icon>
            <ion-label>Anfrage verfassen</ion-label>
          </ion-item>

          <ion-item :router-link="{ path: '/faq'}" @click="closeMenu()">
            <ion-icon :icon="chatbubblesOutline"></ion-icon>
            <ion-label>Fragen (FAQ)</ion-label>
          </ion-item>

          <ion-item :router-link="{ path: '/information'}" @click="closeMenu()">
            <ion-icon :icon="informationCircleOutline"></ion-icon>
            <ion-label>Informationen</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonLabel, IonIcon, IonItem, IonMenuButton, IonMenuToggle, IonBreadcrumbs, IonBreadcrumb, IonToolbar, IonHeader, IonList, IonContent, IonMenu, menuController } from '@ionic/vue';
import { clipboardOutline, informationCircleOutline, chatbubblesOutline } from 'ionicons/icons';

export default {
  name: 'App',
  components: {
    IonApp, IonRouterOutlet, IonLabel, IonIcon, IonItem, IonMenuButton, IonMenuToggle, IonBreadcrumbs, IonBreadcrumb, IonToolbar, IonHeader, IonList, IonContent, IonMenu,
  },
  setup() {
    return {
      clipboardOutline,
      informationCircleOutline,
      chatbubblesOutline,
    }
  },
  methods: {
    closeMenu() {
      menuController.close('first');
    }
  }
};
</script>

<style scoped>
  ion-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  ion-breadcrumb {
    font-size: 12px;
    font-weight: 400;
    --color: #fff;
    --color-active: #fff;
  }
</style>