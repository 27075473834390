import { createStore } from 'vuex';

const MUTATIONS = {
  showBottomTabs: 'showBottomTabs',
};

const store = createStore({
  state() {
    return {
      layout: {
        showBottomTabs: true,
      },
    };
  },
  mutations: {
    [MUTATIONS.showBottomTabs](state, visible) {
      state.layout.showBottomTabs = visible;
    }
  },
  actions: {
    [MUTATIONS.showBottomTabs](context, visible) {
      context.commit(MUTATIONS.showBottomTabs, visible);
    }
  },
  getters: {
    [MUTATIONS.showBottomTabs](state) {
      return state.layout.showBottomTabs;
    },
  },
});

export default store;