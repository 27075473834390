<template>
  <BaseLayout :noHeader="true" :classes="{'ion-padding': true, 'bg-primary': true}">

    <div class="logo">
     <h1>NEW<span>LIFE</span></h1>
    </div>

  </BaseLayout>
</template>

<script>
// import { IonImg } from '@ionic/vue';
import { createAnimation, useIonRouter } from '@ionic/vue';

export default {
  name: 'IntroPage',
  components: {
    // IonImg
  },
  mounted() {
    this.$store.dispatch('showBottomTabs', false);

    const myAnimation = (baseEl, opts) => {
      const { enteringEl, leavingEl } = opts;

      const enteringPage = createAnimation('entering-page-animation')
        .addElement(enteringEl)
        .fromTo('opacity', 0, 1);

      const leavingPage = createAnimation('leaving-page-animation')
        .addElement(leavingEl)
        .fromTo('opacity', 1, 0);

      return createAnimation('root-transition')
        .duration(1000)
        .easing('ease-in-out')
        .addAnimation([enteringPage, leavingPage]);
    }

    const ionRouter = useIonRouter();

    setTimeout(() => {
      ionRouter.push('/home', myAnimation);

      // setTimeout(() => {
      //   this.$store.dispatch('showBottomTabs', true);
      // }, 1000);
    }, 1500);
  }
};
</script>

<style scoped>
  .logo {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
  }
  h1 {
    text-align: center;
    color: #fff;
    font-weight: 100;
    font-size: 30px;
  }
  h1 span {
    font-weight: 600;
  }
</style>