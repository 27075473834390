<template>
  <ion-page>
    <ion-header v-if="noHeader != true" :translucent="true">
      <ion-toolbar ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-toggle>
            <ion-menu-button></ion-menu-button>
          </ion-menu-toggle>
        </ion-buttons>

        <ion-breadcrumbs mode="ios" slot="start">
          <ion-breadcrumb>KÖ Aesthetics</ion-breadcrumb>
          <ion-breadcrumb v-for="(title, key) in pageTitle.split('/')" :key="key">{{ title }}</ion-breadcrumb>
        </ion-breadcrumbs>

        <!-- <ion-title v-if="pageTitle">{{ pageTitle }}</ion-title> -->
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true"  id="main" :class="classes">
      <slot />
    </ion-content>

    <slot name="extended" />
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, /*IonTitle,*/ IonContent, IonBreadcrumbs, IonBreadcrumb, IonButtons, IonMenuButton, IonMenuToggle } from '@ionic/vue';

export default {
  components: {
    IonPage, IonHeader, IonToolbar, /*IonTitle,*/ IonContent, IonBreadcrumbs, IonBreadcrumb, IonButtons, IonMenuButton, IonMenuToggle,
  },
  props: [
    'pageTitle', 'noHeader', 'classes'
  ],
  setup() {
    return {
    }
  },
}
</script>

<style scoped>
  ion-breadcrumb {
    font-size: 12px;
    font-weight: 400;
    --color: #fff;
    --color-active: #fff;
  }
</style>