<template>
  <ion-page>
    <ion-router-outlet></ion-router-outlet>

<!-- <ion-tabs>
      <ion-tab-bar slot="bottom" v-if="showBottomTabs" class="slideIn">

        <ion-tab-button tab="request" href="/request/home">
          <ion-icon :icon="clipboardOutline"></ion-icon>
          <ion-label>Anfrage verfassen</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="faq" href="/faq">
          <ion-icon :icon="chatbubblesOutline"></ion-icon>
          <ion-label>Fragen (FAQ)</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="information" href="/information">
          <ion-icon :icon="informationCircleOutline"></ion-icon>
          <ion-label>Informationen</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs> -->
  </ion-page>
</template>

<script>
import { IonRouterOutlet, IonPage } from '@ionic/vue';

// import { IonLabel, IonIcon, IonRouterOutlet, IonPage, IonItem, IonMenuToggle } from '@ionic/vue';
// import { clipboardOutline, informationCircleOutline, chatbubblesOutline } from 'ionicons/icons';

export default {
  name: 'MainPage',
  components: {
    // IonLabel, IonIcon, IonRouterOutlet, IonPage, IonItem, IonMenuToggle
    IonRouterOutlet, IonPage
  },
  setup() {
    return {
      // clipboardOutline,
      // informationCircleOutline,
      // chatbubblesOutline,
    }
  },
  computed: {
    showBottomTabs() {
      return this.$store.getters.showBottomTabs;
    }
  }
}
</script>

<style scoped>
  ion-label {
    padding-left: 20px !important;
  }
</style>