<template>
  <BaseLayout pageTitle="Anfrage" :classes="{'ion-padding': true, 'bg-white': true}">

  <div class="header">
    <ion-img src="/assets/team.jpg" />
  </div>

  <h1 class="mt2">Wer sind wir?</h1>
  <p>
    Seit nun über 15 Jahren beweist die Schönheitschirurgie KÖ-Aesthetics in Düsseldorf ihre Expertise in Hinblick auf Eingriffe im Bereich der Ästhetisch-Plastischen Chirurgie.
  </p>

  <h1 class="mt3">Worum geht es bei Dir?</h1>
  <p>
    Wähle im folgenden Schritt Dein Körperteil aus, zu dem Du eine Frage hast.
  </p>

  <ion-button class="mt4" router-link="/request/bodypart" router-direction="forward" expand="block" shape="round">Körperteil wählen</ion-button>

  </BaseLayout>
</template>

<script>
import { IonImg, IonButton } from '@ionic/vue';

export default  {
  name: 'Tab1Page',
  components: {
    IonImg, IonButton,
  },
  mounted() {
    this.$store.dispatch('showBottomTabs', true);
  }
};
</script>

<style scoped>
  .header {
    padding: 0px 0 15px 0;
  }

  .header ion-img {
    width: 100%;
  }

  ion-button {
    margin: 25px 0 0 0;
  }
</style>