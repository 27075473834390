import { createRouter, createWebHistory } from '@ionic/vue-router';
// import HomePage from '../views/HomePage.vue';
import IntroPage from '../views/IntroPage.vue';
// import TestPage from '../views/TestPage.vue';
import HomePage from '../views/HomePage.vue';
import MainPage from '../views/MainPage.vue';
import HomeTab from '../views/tabs/HomeTab.vue';
import RequestHomeTab from '../views/tabs/request/HomeTab.vue';

const routes = [
  {
    path: '/',
    // component: TestPage,
    redirect: '/intro'
  },
  {
    path: '/intro',
    component: IntroPage,
  },
{
    path: '/home',
    component: HomePage,
  },
  {
    path: '/',
    component: MainPage,
    children: [
      {
        path: 'home2',
        component: HomeTab,
      },
      {
        name: 'request',
        path: 'request/home',
        component: RequestHomeTab,
      },
      {
        path: 'request/bodypart',
        component: () => import('@/views/tabs/request/BodypartTab.vue'),
      },
      {
        path: 'request/questions',
        component: () => import('@/views/tabs/request/QuestionsTab.vue'),
      },
      {
        path: 'request/photos',
        component: () => import('@/views/tabs/request/PhotosTab.vue'),
      },
      {
        path: 'faq',
        component: () => import('@/views/tabs/FaqTab.vue')
      },
      {
        path: 'information',
        component: () => import('@/views/tabs/InformationTab.vue')
      },

    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
